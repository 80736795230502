import { useEffect, useState } from "react";
import { isAndroid, isIOS } from "react-device-detect";

export default function useDeviceSpecificAppDownloadButton() {
  const [showAndroidButton, setShowAndroidButton] = useState(false);
  const [showIOSButton, setShowIOSButton] = useState(false);

  useEffect(() => {
    const isNotAndroidOrIOS = !isAndroid && !isIOS;
    setShowAndroidButton(isAndroid || isNotAndroidOrIOS);
    setShowIOSButton(isIOS || isNotAndroidOrIOS);
  }, []);

  return { showAndroidButton, showIOSButton };
}
