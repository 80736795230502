import { useEffect, useState } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { appStoreUrl, playStoreUrl } from "../constants/constants";
import { websiteDomain } from "../constants/domain";

export default function useDeviceSpecificAppDownloadLink() {
  const fallbackDownloadPage = websiteDomain + "/downloads";
  const [downloadLink, setDownloadLink] = useState(fallbackDownloadPage);

  useEffect(() => {
    if (isIOS) {
      setDownloadLink(appStoreUrl);
    } else if (isAndroid) {
      setDownloadLink(playStoreUrl);
    }
  }, []);

  return downloadLink;
}
