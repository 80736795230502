import cls from "classnames";
import { Transition } from "@headlessui/react";

const BottomSheetModalHOC = ({ children, breakpoint="sm", className=""}) => {
  return (
    <Transition.Child
      enter={`duration-300`}
      enterFrom={`translate-y-full ${breakpoint}:translate-y-0 opacity-0 ${breakpoint}:scale-90`}
      enterTo={`translate-y-0 opacity-100 ${breakpoint}:scale-100`}
      leave={`duration-300`}
      leaveFrom={`translate-y-0 opactity-100 ${breakpoint}:scale-100`}
      leaveTo={`translate-y-full opacity-0 ${breakpoint}:translate-y-0 ${breakpoint}:scale-90`}
      className={cls(`fixed max-h-screen overflow-y-scroll ${breakpoint}:relative ${breakpoint}:inline-block ${breakpoint}:align-middle bottom-0 left-0 z-20 w-full pt-14 ${breakpoint}:py-14 hide-scrollbar`, className)}
    >
      {children}
    </Transition.Child>
  );
};

export default BottomSheetModalHOC;
