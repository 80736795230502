import { useCallback } from "react";
import Link from "next/link";

import * as Track from "@qoohoo/common/utils/analytics/track";
import Modal from "@qoohoo/common/library/modals/Modal";
import BottomSheetModalHOC from "@qoohoo/common/library/modals/BottomSheetModalHOC";
import useStore from "../store/store";
import { button, pageview } from "@qoohoo/common/constants/analytics";
import useDeviceSpecificAppDownloadLink from "../hooks/useAppDownloadLink";
import useDeviceSpecificAppDownloadButton from "../hooks/useAppDownloadButton";

const GetStartedModal = () => {
  const isModalOpened = useStore((state) => state.isGetStartedModalOpened);
  const setIsModalOpened = useStore(
    (state) => state.setIsGetStartedModalOpened
  );

  const downloadLink = useDeviceSpecificAppDownloadLink();
  const { showAndroidButton, showIOSButton } =
    useDeviceSpecificAppDownloadButton();

  const closeModal = () => {
    setIsModalOpened(false);
  };

  return (
    <Modal isOpen={isModalOpened} closeModal={closeModal}>
      <BottomSheetModalHOC>
        <div className="inline-flex items-end justify-center w-full h-full sm:items-center font-nunito">
          <div className="relative bg-modal-bg shadow-xl rounded-t-2xl sm:rounded-2xl max-w-full w-full sm:max-w-[360px] sm:w-[360px]">
            <img
              src="/assets/icons/close-icon.svg"
              lt="close button"
              onClick={closeModal}
              className="absolute cursor-pointer right-4 top-4"
            />
            <img
              src="/assets/images/doodle-rocket.svg"
              alt="Doodle arrow poiting to Get Started Button"
              className="absolute -top-10 right-20 select-none"
            />
            <div className="w-full px-6 pb-10 pt-[66px]">
              <div className="font-nunito font-bold text-[32px] leading-[45px]">
                <span className="text-white">Let's get</span>{" "}
                <span className="text-[#aefa80]">started..</span>
              </div>
              <div className="relative">
                <img
                  src="/assets/images/doodle-arrow-cta.svg"
                  alt="Doodle arrow poiting to Get Started Button"
                  className="absolute w-28 transform translate-x-[68px] md:translate-x-10 -translate-y-6 rotate-[-20deg] select-none"
                />
              </div>
              <div className="mt-20 mb-8">
                <p className="text-[#F0F0F0] px-4">
                  Walk a successful creator journey with qoohoo. Like a pro! ✌
                </p>
              </div>
              <div className="flex justify-center">
                <Link
                  href={downloadLink}
                  className="flex font-inter w-max relative font-semibold text-[#16191B] text-lg py-4 sm:py-[18px] px-8 bg-white rounded-full button-solid-shadow hover:scale-105 duration-200 transition-all get-started-button outline-none"
                  onClick={useCallback(() => {
                    Track.buttonClick(button.download, {
                      url: downloadLink,
                      current_screen: pageview.home,
                    });

                    closeModal();
                  })}
                >
                  <span className="flex items-center">
                    {showAndroidButton && (
                      <img src="/assets/google-play.png" className="h-6 mr-4" />
                    )}
                    {showIOSButton && (
                      <img src="/assets/apple-store.png" className="h-6 mr-4" />
                    )}
                    <span>Download Now</span>
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </BottomSheetModalHOC>
    </Modal>
  );
};

export default GetStartedModal;
